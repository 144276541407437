@import '../../Global.scss';

#work-wrapper {}

#description-container {
    opacity: 0;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    height: 350px;
}

#logo-carousel {
    position: absolute;
    display: flex;
    h3 {
        position: relative;
        display: inherit;
        align-items: center;
        opacity: 0.2;
        color: $GlobalWhite;
        font-family: 'Khand Bold';
        font-size: 60px;
        margin-right: 75px;
    }
}

#description-text {
    position: absolute;
    right: auto;
    top: 50%;
    transform: translate(0, -50%);

    @include sm {
        right: 0;
    }

    h3 {
        font-family: 'Supreme Light';
        color: $GlobalWhite;
        font-size: 20px;
        width: 90%;
        font-size: 20px;
        margin-bottom: 10px;

        @include sm {
            width: 100%;
            font-size: 25px;
            padding-bottom: 15px;
            width: 350px;
        }

        @include xl {
            font-size: 28px;
        }
    }
}

#table-container {
    opacity: 0;
    color: $GlobalWhite;
    padding-bottom: 90px;

    a {
        color: $GlobalWhite;
    }

    @include sm {
        // padding: 35px 110px;
    }

    .table-row {
        border-bottom: 1px solid $GlobalWhite;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 20px;

        @include xl {
            margin-bottom: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center;
        }

        .divider {
            border: none;
            @include xl {
                border-right: 1px solid $GlobalWhite;
            }
        }

        img {
            height: 35px;
            width: 35px;
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translate(-50% , -50%);

            @include sm {
                height: 50px;
                width: 50px;
            }
        }

        .table-cell {
        
            @include xl {
                padding: 15px 20px;
            }

            .table-header {
                font-family: 'Khand Bold';
                font-size: 30px;
                
                @include sm {
                    font-size: 35px;
                    width: 225px;
                }
            }

            .table-label {
                width: 85%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 18px;
                font-family: 'Supreme Light';

                @include sm {
                    width: 100%;
                    font-size: 30px;
                }
            }
        }
    }
}