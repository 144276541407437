@import '../../../../Global.scss';

.description-container {

    cursor: pointer;
    color: $GlobalWhite;
    position: relative;
    border-bottom: 1px solid $GlobalWhite;
    margin-bottom: 60px;

    &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
    }

    span {
        font-family: 'Supreme Light';
        font-size: 20px;

        @include lg {
            font-size: 25px;
        }
    }

    h4 {
        font-family: 'Khand Bold';
        font-size: 40px;

        @include lg {
            font-size: 50px;
        }
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        
        img {
            width: 25px;
            transform: rotate(45deg);

            @include lg {
                width: 30px;
            }
        }
    }

    > div {
        height: 0; // default height
        overflow: hidden;
        padding-top: 15px;

        @include lg {
            display: flex;
            justify-content: space-between;
        }

        div {
            width: 100%;

            @include lg {
                width: 50%;
            }

            a {
                color: $GlobalBlue;
                border-bottom: 1px solid $GlobalBlue;
            }

            p {
                font-family: 'Supreme Regular';
                font-size: 20px;
                width: 100%;
                margin-bottom: 15px;

                @include lg {
                    width: 100%
                }
            }

            &:last-of-type {
                display: flex;
                justify-content: center;

                img {
                    width: 100%;

                    @include lg {
                        height: 300px;
                        width: 300px;
                    }
                }
            }
        }
    }
}