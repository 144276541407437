@import './fonts/fonts.scss';
@import './Breakpoints.scss';

// KHAND PRESET
$KhandRegular: 'Khand Regular';
$KhandMedium: 'Khand Medium';
$KhandBold: 'Khand Bold';

// SUPREME PRESET
$SupremeRegular: 'Supreme Regular';
$SupremeMedium: 'Supreme Medium';
$SupremeBold: 'Supreme Bold';

// PRIMARY COLOR PALLETTE
$GlobalBlack: #0E1111;
$GlobalBlue: #0496FF;
$GlobalWhite: #F5EFED;
$GlobalRed: #A30000;

// PRIMARY SIZES
$GlobalPaddingLeftRight: 40px;
$GlobalHeader2: 35px;
$GlobalParagraph: 25px;

* {
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

// reset default button styles
button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.page-wrapper {
    box-sizing: border-box;
    padding: 0 $GlobalPaddingLeftRight;
    position: relative;
    background-color: $GlobalWhite;
    height: 100vh;
    width: 100vw;
    font-family: $KhandBold;

    @include sm {
        padding: 0 $GlobalPaddingLeftRight + 15px;
    }

    @include md {
        padding: 0 $GlobalPaddingLeftRight + 30px;
    }

    @include mobile-landscape {
        height: 750px; // static height to ensure scroll room
    }
}

.inverted-wrapper {
    background-color: $GlobalBlack;
    height: 100%;
}

.hero {
    padding: 150px 0 0px 0;
    position: relative;

    // @include xl {
    //     padding: 150px 0;
    // }

    > div {
        position: relative;
        display: inline-block;
        .txl {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $GlobalWhite;
        }

        h1 {
            color: $GlobalWhite;
            font-size: 125px;
            line-height: 180px;

            @include sm {
                font-size: 175px;
            }
        }
    }
}