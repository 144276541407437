// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }

 @mixin mobile-portrait {
    @media only screen and (hover: none) and (pointer: coarse) and (orientation: portrait) {
        @content;
    }
 }

 // mobile landscape mode
 @mixin mobile-landscape {
    @media only screen and (hover: none) and (pointer: coarse) and (orientation: landscape) {
        @content;
    }
 }