@import '../../Global.scss';

#about-section-wrapper {
    opacity: 0;

    .reversed {
        flex-direction: column-reverse !important;

        @include lg {
            flex-direction: row-reverse !important;
        }
    }
    
    .about-section-container {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;

        align-items: center;

        padding-bottom: 100px;
    
        &:first-of-type {
            padding-top: 100px;
        }

        &:last-of-type {
            padding-bottom: 150px;
        }


        @include lg {
            flex-direction: row;
        }

        .about-section {
            margin-top: 50px;
            width: 100%;
            @include lg {
                width: 50%;
            }
        }

        .text {
            margin-top: 40px;

            @include sm {
                margin-top: 80px;
            }
            color: $GlobalWhite;
            $secondaryMargin: 75px;

            h4 {
                justify-content: flex-start;
                font-size: $GlobalHeader2;
                font-family: 'Khand Bold';

                @include md {
                    font-size: $GlobalHeader2 + 5px;
                }

                @include xl {
                    font-size: $GlobalHeader2 + 10px;
                }

                &:last-of-type {
                    margin-left: 0;

                    @include lg {
                        margin-left: $secondaryMargin;
                    }
                }
            }

            > div {
                margin-top: 30px;
                
                @include lg {
                    width: 80%;
                    margin-left: $secondaryMargin;
                }
                
                p {
                    font-size: $GlobalParagraph;

                    @include md {
                        font-size: $GlobalParagraph + 5;
                    }
                    

                    font-family: 'Supreme Regular';

                    a {
                        color: $GlobalBlue;
                        text-decoration: underline;
                    }
                }
            }
        }
        
        .image {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 250px;
                margin-bottom: 50px;

                @include md {
                    margin-bottom: 0;
                    width: 350px;
                }
            }
        }
    }
}