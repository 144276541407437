@import '../../Global.scss';

.page-renderer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: $GlobalBlack;
    z-index: 5;
}