@import '../../Global.scss';

#home-title-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home-title-container {
    position: relative;
    
    .txl-container {

        position: relative;

        &:last-of-type {
            margin-bottom: -25px;
        }

        .home-txl {
            background-color: black;
            position: absolute;
            height: 100%;
            width: 100%;
        }

        h1 {
            color: $GlobalBlack;

            font-size: 90px;
            line-height: 90px;
            
            @include sm {
                font-size: 150px;
                line-height: 150px;
            }

            @include xl {
                font-size: 200px;
                line-height: 200px;
            }
        }
    }

    #cr-year {
        opacity: 0; // starting value, before animation intro
        margin-top: 30px;
        text-align: left;
        font-family: $SupremeRegular;

        @include sm {
            text-align: left;
            bottom: 25px; // starting value, before animation intro
        }
    }
}