@import '../../Global.scss';

.scroll-button-container {
    opacity: 0; // starting animation value
    z-index: -5; // prevents collision on 0 opacity
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: $GlobalBlue;

    
    img {
        position: absolute;
        top: 45%;
        transform: translate(-50%, -50%);
        rotate: 90deg;

        height: 25px;
        width: 25px;
    }
}