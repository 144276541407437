/* CUSTOM FONTS */

// KHAND - https://www.fontshare.com/fonts/supreme

@font-face {
    font-family: 'Khand Regular';
    src: local('Khand Regular'), url(../fonts/Khand/Khand-Regular.otf);
}

@font-face {
    font-family: 'Khand Medium';
    src: local('Khand Medium'), url(../fonts/Khand/Khand-Medium.otf);
}

@font-face {
    font-family: 'Khand SemiBold';
    src: local('Khand SemiBold'), url(../fonts/Khand/Khand-SemiBold.otf);
}

@font-face {
    font-family: 'Khand SemiBold';
    src: local('Khand SemiBold'), url(../fonts/Khand/Khand-SemiBold.otf);
}

@font-face {
    font-family: 'Khand Bold';
    src: local('Khand Bold'), url(../fonts/Khand/Khand-Bold.otf);
}

// SUPREME - https://www.fontshare.com/fonts/supreme

@font-face {
    font-family: 'Supreme Light';
    src: local('Supreme Light'), url(../fonts//Supreme/Supreme-Light.otf);
}

@font-face {
    font-family: 'Supreme Regular';
    src: local('Supreme Regular'), url(../fonts/Supreme/Supreme-Regular.otf);
}

@font-face {
    font-family: 'Supreme Medium';
    src: local('Supreme Medium'), url(../fonts/Supreme/Supreme-Medium.otf);
}

@font-face {
    font-family: 'Supreme Bold';
    src: local('Supreme Bold'), url(../fonts/Supreme/Supreme-Bold.otf);
}
