@import '../../Global.scss';

.next-navigation-container {
    padding: 50px $GlobalPaddingLeftRight;
    background-color: $GlobalWhite;

    @include sm {
        padding: 50px $GlobalPaddingLeftRight + 15px;
    }

    @include xl {
        padding: 50px $GlobalPaddingLeftRight + 30px;
    }

    > div {
        position: relative;
        width: 125px;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 100px;

            @include xl {
                width: 125px;
            }
        }

        h4 {
            color: $GlobalBlack;
            font-family: 'Supreme Light';
            font-size: 25px;
            
            @include xl {
                font-size: 32px;
            }
        }
    }

    h3 {
        color: $GlobalBlack;
        font-family: 'Khand Bold';
        font-size: 75px;
        line-height: 75px;

        @include xl {
            font-size: 125px;
            line-height: 125px;
        }
    }
}