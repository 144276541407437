@import '../../Global.scss';

.title-text {
    color: $GlobalWhite;

    h4 {
        font-family: 'Supreme Light';
        font-size: 20px;

        a {
            color: $GlobalBlue;
            text-decoration: underline;;
        }
    }

    h2 {
        font-family: 'Khand Bold';
        font-size: 75px;
        line-height: 75px;
        margin-bottom: 15px;

        @include md {
            font-size: 100px;
        }
    }
}

.input-error {
    input {
        border-bottom: 2px solid $GlobalRed !important;
    }

    p {
        color: $GlobalWhite;
        font-family: $SupremeRegular;
        font-size: 25px;
    }
}

.error-container {
    margin-top: 25px;
    color: $GlobalWhite;
    font-family: $SupremeRegular;
    font-size: 20px;
}

#work-password-container {
    width: 100%;
    padding-bottom: 150px;

    @include md {
        min-height: 40vh;
    }

    #input-container {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include md {
            flex-direction: row;
            width: 75%;
        }
        
        input {
            font-size: 30px;
            padding-bottom: 10px;
            width: 100%;
            border: none;
            border-bottom: 2px solid $GlobalWhite;
            background: none;
            outline: none;
            color: $GlobalWhite;
            font-family: $SupremeRegular;

            @include md {
                width: 90%;
            }
        }

        button {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 35px;
            margin-top: 25px;
            color: $GlobalWhite;
            font-family: $KhandBold;

            @include md {
                margin-top: 0;
                margin-left: 25px;
                width: 10%;
            }

            &:hover {
                color: $GlobalBlue;
            }
        }
    }
}

#navigation-container {
    padding: 150px 0 75px 0;

    @include sm {
        padding: 150px 0 100px 0;
    }

    a {
        display: flex;
        align-items: center;
        
        img {
            width: 15px;

            @include sm {
                width: 15px;
            }
        }
    
        p {
            color: $GlobalWhite;
            font-family: 'Supreme Regular';
            font-size: $GlobalParagraph - 5px;
            margin-left: 20px;
        }
    }
}

#title-container {
    align-items: center;
    padding-bottom: 30px;

    @include lg {
        display: flex;
        justify-content: space-between; 
    }

    h3 {
        color: $GlobalWhite;
        font-size: 26px;
        font-family: 'Supreme Light';

        @include md {
            font-size: 32px;
        }
    }
}

#hero-image-container {
    width: 100%;
    padding-bottom: 80px;
    display: flex;
    justify-content: center;

    img {
        width: 100%;

        @include lg {
            width: 75%;
        }
    }
}

#accordion-container {
    padding-bottom: 30px;
}
