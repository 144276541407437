@import '../../Global.scss';

.menu-bar-container {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    opacity: 0; // initial value before animation
    top: 0px; // initial value before animation
    right: 30px;
    border: 5px solid $GlobalBlack;
    border-radius: 50px;
    padding: 5px 35px;

    @include sm {
        padding: 10px 45px;
    }

    #dsktp-label {
        display: none;
        font-family: 'Khand Bold';
        font-size: 25px;
        color: $GlobalBlack;

        @include sm {
            display: block;
        }
    }

    #mbl-label {
        @include sm {
            display: none;
        }
    }
}

.bar-inverted {
    border-color: $GlobalWhite;

    #dsktp-label {
        color: $GlobalWhite;
    }
}

.menu-options-container {
    display: flex; // initial style before animations
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
    background-color: $GlobalBlack;

    justify-content: center;
    align-items: center;

    @include mobile-landscape {
        justify-content: center;
        align-items: flex-start;
        overflow-y: scroll;
    }

    #exit-icon {
        opacity: 0;
        position: absolute;
        top: 45px;
        left: 30px;
        
        img {
            height: 50px;
            width: 50px;
        }
    }
    
    > div {
        .menu-txl-container {
            opacity: 0; // initial value before animation
            position: relative;
            z-index: 5;

            .animation-hover-arrow {
                z-index: -1;
                left: -25%; // start 150px left of left side
                opacity: 0; // start at empty

                display: flex;
                align-items: center;
                position: absolute;
                height: 100%;
                width: 150%;

                // background-color: red;

                img {
                    height: 30px;
                    transform: rotate(180deg);
                    position: absolute;
                }

                .inverted {
                    background-color: $GlobalBlack;
                }

                > div {
                    background-color: $GlobalBlue;
                    height: 2px;
                    width: 15px;
                }
            }

            h1 {
                color: $GlobalWhite;
                font-family: 'Khand Bold';
                font-size: 75px;

                @include sm {
                    font-size: 150px;
                }
    
                @include xl {
                    font-size: 200px;
                }
            }
        }
    }
}

.menu-inverted {
    background-color: $GlobalWhite;

    > div {
        .menu-txl-container {
            h1 {
                color: $GlobalBlack;
            }
        }
    }
}